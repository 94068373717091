<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap my-orders">
      <!-- 导航 -->
      <div class="order-navigation">
        <a
          class="text1"
          href="/#/catalogue"
        >{{L.home}}</a>
        <span class="line">/</span>
        <!-- 我的订单 -->
        <a
          class="text2"
          href="/#/myOrders" 
        >{{L.myOrder}}</a>
      </div>
      <!-- 我的订单-标题 -->
      <div class="my-orders-title">{{L.myOrder}}</div>
      <!-- 搜索 -->
      <div class="search-wrap">
        <el-form :model="search" ref="formSearch" :rules="rules" :show-message="false">
          <div class="square">
            <!-- 订购日期从 -->
            <span class="text">{{L.orderDateFrom}}: </span>
            <el-form-item prop="dateFrom">
              <el-date-picker
                class="search-field"
                v-model="search.dateFrom"
                value-format="yyyy-MM-dd"
                format="dd-MM-yyyy"
                :picker-options="pickerOptions"
                type="date">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="square">
            <!-- 订购日期到 -->
            <span class="text">{{L.orderDateTo}}: </span>
            <el-form-item prop="dateTo">
              <el-date-picker
                class="search-field"
                v-model="search.dateTo"
                value-format="yyyy-MM-dd"
                format="dd-MM-yyyy"
                :picker-options="pickerOptions"
                type="date">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="square">
            <!-- EBS订单编号 -->
            <span class="text">{{L.orderID}}:</span>
            <el-form-item prop="ebsorderNo">
              <el-input @keyup.enter.native="getOrderList" v-model="search.ebsorderNo" class="search-field"></el-input>
            </el-form-item>
          </div>
          <div class="square">
            <!-- 采购订单编号 -->
            <span class="text">{{L.PONumber}}:</span>
            <el-form-item prop="poreference">
              <el-input @keyup.enter.native="getOrderList" v-model="search.poreference" class="search-field"></el-input>
            </el-form-item>
          </div>
          <div class="square">
            <!-- 物料号 -->
            <span class="text">{{L.articleNumber}}:</span>
            <el-form-item prop="articleNumber">
              <el-input @keyup.enter.native="getOrderList" v-model="search.articleNumber" class="search-field"></el-input>
            </el-form-item>
          </div>
          <!-- 订单状态-选择框 -->
          <div class="square order-status" v-if="true">
            <!-- 订单状态 -->
            <span class="text">{{L.orderStatus}}:</span>
            <div class="select-status" @mouseleave="mouseleave(1)">
              <el-form-item prop="orderStatus">
                <!-- 订单状态-input框 -->
                <div class="inp-wrap" @click="clickChooseStatus">
                  <div class="left-text">{{orderStatusText}}</div>
                  <div class="arrow">
                    <span class="iconfont icon_arrow" :class="{'active-arrow':show.showChoose}">&#xeb8b;</span>
                  </div>
                </div>
              </el-form-item>
              <!-- 弹框 -->
              <transition
                  @enter="enter"
                  @afterEnter="afterEnter"
                  @leave="leave"
                  @afterLeave="afterLeave"
                >
                  <div class="choose-big" v-show="show.showChoose">
                    <span class="triangle"></span>
                    <div class="choose-wrap">
                      <div class="choose" @click="chooseStatus(item,i)" v-for="item,i in orderStatusArr" :key="item.statusCode">
                        <div class="line1" :class="{'active-color':item.showTick}">{{item.statusValue}}</div>
                        <span class="iconfont icon_tick" v-show="item.showTick">&#xe8e6;</span>
                      </div>
                    </div>
                  </div>
              </transition>
            </div>
          </div>
          <div class="square">
            <!-- 发货至 -->
            <span class="text">{{L.shipTo}}:</span>
            <el-form-item prop="shipTo">
              <el-select v-model="search.shipTo" class="search-field" placeholder="">
                <el-option
                  v-for="item in shipToArr"
                  :key="item.siteUseLocation"
                  :label="item.siteUseLocation"
                  :value="item.siteUseLocation">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 按钮 -->
      <div class="btn-wrap">
        <div class="left">
          <!-- 订单概述 (xlsx) -->
          <div class="fill" @click="downloadList">{{L.orderOoverview}}</div>
          <!-- 订单详情 (xlsx) -->
          <div class="fill" @click="downloadDetail">{{L.orderDetails}}</div>
        </div>
        <div class="right">
          <!-- 清除过滤 -->
          <div class="null" @click="handleClearFilters">{{L.cleaFilters}}</div>
          <!-- 显示未关闭订单 -->
          <div class="null" @click="showOpenOrders">{{L.showOpenOrders}}</div>
          <!-- 搜索 -->
          <div class="fill" @click="getOrderList">{{L.search}}</div>
        </div>
      </div>
      <!-- 表格 -->
      <div class="table-wrap">
        <el-table
          :default-sort="{prop:'ebsorderNo',order:'descending'}"
          :data="orderList"
          style="width: 100%"
        >
          <el-table-column
            prop="ebsorderNo"
            :sortable="true"
            :label="tableLable.orderID">
              <template slot-scope="scope">
                <a 
                  :href="'/#/orderDetail?ebsorderNo='+scope.row.ebsorderNo" 
                  class="order-id"
                >{{scope.row.ebsorderNo}}</a>
              </template>
          </el-table-column>
          <el-table-column
            prop="poreference"
            sortable
            :label="tableLable.PONumber">
          </el-table-column>
          <el-table-column
            prop="orderDate"
            sortable
            :label="tableLable.orderDate"
          >
            <template slot-scope="scope">
              <span>
                {{ formatDate(scope.row.orderDate.split(' ')[0]) }}
                {{ scope.row.orderDate.split(' ')[1] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="orderStatus"
            :label="tableLable.orderStatus">
          </el-table-column>
          <el-table-column
            v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP')"
            prop="totalAmount"
            sortable
            :label="tableLable.orderTotalAmount"
          >
            <template slot-scope="scope">
              <span>{{ formatMoney(scope.row.totalAmount,scope.row.currency) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="placedVia"
            :label="tableLable.placedVia">
            <template slot-scope="scope">
              <div class="place-via">
                <span>{{ scope.row.placedVia }}</span>
                <el-tooltip
                 v-if="scope.row.placedVia=='Migrated'"
                  class="item" 
                  effect="dark"
                  placement="top"
                >
                <div slot="content">This new EBS order ID contains the open items from your original order that we have migrated to our new system. Items of original order got cancelled accordingly. Sort /search by PO number to see both orders.</div>
                  <span class="iconfont icon-info">&#xed50;</span>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="shipToInfo"
            :label="tableLable.shipTo">
          </el-table-column>
          <!-- 表格没数据的时候展示空组件，要注意这里的slot=empty不能随意更改 -->
          <div slot="empty">
            <NoContent/>
          </div>
        </el-table>
      </div>
    </div>
    
    
  </div>
</template>

<script>
import {postAction,downFilePost} from '@/api/tool'
import {api_order_list,api_order_list_download,api_order_list_detail_download,api_order_select_data} from '@/api/api'
import NoContent from '@/components/NoContent'
import moment from 'moment'

export default {
  name:'Catalogue',
  components:{
    NoContent
  },
  data(){
    return {
      show:{
        showChoose:false
      },
      search:{
        dateFrom:'',
        dateTo:'',
        ebsorderNo:'',
        poreference:'',
        orderStatus:'',
        shipTo:'',
        articleNumber:''
      },
      rules:{
        // dateFrom:[{required:true,trigger:'blur'}],
        // dateTo:[{required:true,trigger:'blur'}]
      },
      orderStatusText:'', //状态，用逗号隔开(搜索那边的状态)
      orderList:[], //列表数据
      orderStatusArr:[], //order status下拉数据
      shipToArr:[], //ship to下拉数据
      pickerOptions:{
        disabledDate: e => {
          let chooseDate = moment(new Date(e)).format('YYYY-MM-DD') //当选择的日期
          let chooseTime = new Date(chooseDate).valueOf() //选择的时间戳
          let date = moment(new Date()).format('YYYY-MM-DD') //当前日期
          let dateTime = new Date(date).valueOf() //当前日期时间戳
          //小于等于今天的时间，可以选择。反之不能选择
          if(chooseTime<=dateTime){
            return false
          }else{
            return true
          }
        }
      },//日期
      tableLable:{
        orderID:'',
        PONumber:'',
        orderDate:'',
        orderStatus:'',
        orderTotalAmount:'',
        placedVia:'',
        shipTo:''
      } //表格的表头，因为这个地方切换语言的时候没办法正常渲染，所以需要监听语言包的变化，进行重新渲染
    }
  },
  watch:{
    L(newVal){
      this.tableLable=newVal
    }
  },
  methods:{
    mouseleave(flag){
      if(flag==1){
        this.show.showChoose=false
      }
    },
    /* 单击order status的下拉框，上面的 */
    clickChooseStatus(){
      this.show.showChoose=true
    },
    /* 选择下拉列表中的其中一项 */
    chooseStatus(item,i){
      //点击all的，如果是全选，或者全不选
      if(item.statusCode==0){
        let finalResult = !item.showTick
        this.orderStatusArr.forEach((val,i)=>{
          val.showTick=finalResult
          this.$set(this.orderStatusArr,i,val) //触发渲染
        })
      }else{
        //点击除了all之外的
        item.showTick=!item.showTick
        this.$set(this.orderStatusArr,i,item) //触发渲染
        let allFlag = true //标记是true，说明默认是全部都勾上的
        this.orderStatusArr.forEach((val)=>{
          //不是all的里面如果有一个是false,那all的那个选择就是false
          if(!val.showTick && val.statusCode!=0){
            allFlag=false
          }
        })
        //标记完成后，找到all的那个钩子，去勾上或者不勾上
        this.orderStatusArr.forEach((val,i3)=>{
          if(val.statusCode==0){
            val.showTick=allFlag
            this.$set(this.orderStatusArr,i3,val) //触发渲染
          }
        })
      }
      //无论哪种情况结束后，都需要重新设置orderStatusText和search.orderStatus
      let newOrderStatusText=[] //打钩的文字，排成数组
      let newOrderStatusCode=[] //打钩的id，排成数组
      this.orderStatusArr.forEach((val)=>{
        if(val.showTick){
          newOrderStatusText.push(val.statusValue)
          newOrderStatusCode.push(val.statusCode)
        }
      })
      this.orderStatusText=newOrderStatusText.join(',')
      this.search.orderStatus=newOrderStatusCode.join(',')
    },
    /*
      方法-传入需要选中的数组
      1.把下拉框的值自动勾选上
      2.把下拉框的input框自动填入
      3.把搜索orderStatus参数进行更正
    */
    setSelectStatus(arr){
      let chooseArr = arr
      let newOrderStatusText=[] //打钩的文字，排成数组
      let newOrderStatusCode=[] //打钩的id，排成数组
      this.orderStatusArr.forEach((val,i)=>{
        if(chooseArr.includes(val.statusCode)){
          val.showTick=true
          newOrderStatusText.push(val.statusValue)
          newOrderStatusCode.push(val.statusCode)
          this.$set(this.orderStatusArr,i,val) //触发渲染，把下拉框的值自动勾选上
        }else{
          val.showTick=false
        }
      })
      this.orderStatusText=newOrderStatusText.join(',') //把下拉框的input框自动填入
      this.search.orderStatus=newOrderStatusCode.join(',') //把搜索orderStatus参数进行更正
    },
    /* 获取订单列表 */
    getOrderList(){
      this.$refs.formSearch.validate(valid=>{
        if(!valid){
          this.$message.warning('Please enter the required search field.');
        }else{
          this.allLoading=true
          postAction(api_order_list,this.search).then(res=>{
            this.allLoading=false
            this.orderList=res.header
            this.setSelectStatus(res.status)
          }).catch(()=>{
            this.allLoading=false
          })
        }
      })
    },
    /* 下载列表 */
    downloadList(){
      this.allLoading=true
      downFilePost(api_order_list_download,this.search).then(res=>{
        this.allLoading=false
        this.downloadFile(res)
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 下载详情 */
    downloadDetail(){
      this.allLoading=true
      downFilePost(api_order_list_detail_download,this.search).then(res=>{
        this.allLoading=false
        this.downloadFile(res)
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 重置搜索条件并且直接请求数据 */
    clearFilters(){
      this.search={
        dateFrom:'',
        dateTo:'',
        ebsorderNo:'',
        poreference:'',
        orderStatus:'',
        shipTo:'',
        articleNumber:''
      }
      //清空order status的数据和下拉框的打钩
      this.orderStatusText=''
      this.orderStatusArr.forEach((val,i)=>{
        this.orderStatusArr[i].showTick=false
      })
      this.setDefaultDate()
    },
    handleClearFilters(){
      this.clearFilters()
      this.getOrderList()
    },
    /* 设置默认日期,进到这个路由的时候要设置一下默认日期 */
    setDefaultDate(){
      let date = new Date()
      this.search.dateTo = moment(date).format('YYYY-MM-DD')
      this.search.dateFrom = moment(date).subtract(3,'months').format('YYYY-MM-DD') //回退3个月
    },
    /* 获取order status和ship to的下拉数据 */
    getSelectData(){
      postAction(api_order_select_data).then(res=>{
        this.orderStatusArr=res.orderStatusList
        // this.orderStatusArr.unshift({
        //   showTick:false,
        //   statusCode:0,
        //   statusValue:this.L.all
        // })
        this.shipToArr=res.shipToList
        this.search.orderStatus=''
        this.search.shipTo='0'
      })
    },
    /* 从后端拿新的列表数据 */
    showOpenOrders(){
      this.clearFilters()
      this.search.dateFrom=''
      this.search.dateTo=''
      //自动勾选4,5,7,8,9
      let chooseArr = [4,5,7,8,9]
      this.setSelectStatus(chooseArr)
      this.getOrderList()
    }
  },
  mounted(){
    this.tableLable=this.L
    this.setDefaultDate() //注意这个方法要在getOrderList之前，因为在最开始加载请求的时候，需要先把日期设置进去并且传给后端再去请求数据
    this.getSelectData()
    this.getOrderList()
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>

