<template>
  <div v-loading="allLoading">
    <!-- 上传文件-框 -->
    <div class="upload-wrap">
      <div class="one-line">
        <div class="total-price">
          <!-- 不含税总金额 -->
          <b>{{L.totalAmount}}:</b>
          <!-- 个产品项 -->
          <span>({{$store.state.cartCount}} {{L.orderltems}})</span>
        </div>
        <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP')">
          <div class="price">{{formatMoney(totalPrice || 0)}}</div>
        </template>
        <div @click="downloadCart" class="download">
          <span class="iconfont icon-file">&#xe602;</span>
          <!-- 下载购物车 -->
          <span class="text">{{L.downloadCart}}</span>
        </div>
        <div class="delivery-btn">
          <el-tooltip 
            effect="dark"
            placement="top"
          >
          <!-- 交货和价格检查显示您的最终应付发票成本包括附加费和折扣，加上发货日期。变化将用橙色突出显示。 -->
            <div slot="content">{{L.deliveryRemark}}</div>
            <span class="iconfont icon-info">&#xed50;</span>
          </el-tooltip>
          <span 
            class="no-allowed"
            :class="{'btn':$store.state.userInfo.accountRules && !$store.state.userInfo.accountRules.includes('NOC') && !allQtyIsErrFlag}" 
            @click="priceCheck"
          >{{L.deliveryPriceCheck}}</span>
        </div>
      </div>
      <div class="two-line">
        <div class="btn-wrap btn-wrap1"></div>
        <div class="btn-wrap btn-wrap2"></div>
        <div class="btn-wrap btn-wrap3 btn-wrap4">
          <!-- 隐藏quote -->
          <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SRQ')">
            <div class="btn" @click="handleRequestQuo(1)">
              <div class="wishlist-text">
                <span class="iconfont icon-wishlist">&#xe64a;</span>
                <span class="text">{{L.requestQuo}}</span>
              </div>
            </div>
          </template>
        </div>
        <div class="btn-wrap btn-wrap4">
          <div class="btn" @click="handleShowWishlist(1)">
            <div class="wishlist-text">
              <span class="iconfont icon-wishlist">&#xe60e;</span>
              <span class="text">{{L.addToWishlist}}</span>
            </div>
          </div>
          <div class="wishlist-wrap" v-show="show.showHeadWishlist">
            <WishlistSelect ref="HeadWishlistSelect" @closeWishlistDialogue="closeWishlistDialogue(1)"/>
          </div>
        </div>
      </div>
    </div>
    <!-- 我的购物车-标题 -->
    <div class="my-cart-title">
      <!-- 我的购物车 -->
      <div class="title">{{L.myShoppingCart}}</div>
      <div class="btn-wrap">
        <!-- 快速订购 -->
        <div class="btn" @click="showQuickOrder">{{L.quickOrder}}</div>
      </div>
    </div>
    <!-- 警告 -->
    <div class="warning" v-show="warningMessage">
      <span class="iconfont icon-warning">&#xe6aa;</span>
      <!-- 警示 -->
      <span class="text">{{L.warning}}:{{warningMessage}}</span>
    </div>
    <!-- 订单信息 -->
    <div class="order">
      <div class="title">{{L.orderInfo}}</div>
      <div class="info">
        <div class="please">
          <!-- 请选择送货地址 -->
          <span class="please-left">{{L.PleaseSelectAddress}}:</span>
          <div class="select" :class="{'can-not-change':!canChageShipTo}" @mouseleave="mouseleave(1)">
            <div class="choose" @click="triggerShowShipTo">
              <div class="text">
              <div class="text1">
                {{chooseAddress.addressTitle}} {{chooseAddress.siteUseLocation}}
              </div>
                <div class="text2">{{chooseAddress.addressInfo}}</div>
              </div>
              <span class="iconfont arrow" :class="{'active-arrow':show.showChooseAddress}">&#xe627;</span>
            </div>
            <!-- 弹框 -->
            <div class="choose-wrap" v-show="show.showChooseAddress">
              <div class="choose" @click="chooseAddressSelect(item)" v-for="item in addressList" :key="item.addressId">
                <div class="line1">{{item.addressTitle}} {{item.siteUseLocation}}</div>
                <div class="line2">{{item.addressInfo}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="ship-to-note" v-show="!canChageShipTo">Note: a cart with quote items is fixed to the shipping address of quotation.</div>
        <!-- 请选择交货方 -->
        <!-- 这个送货方不一定有，如果有的话，再展示 -->
        <div class="please" v-if="deliveryList.length>0">
          <!-- 请选择送货地址 -->
          <span class="please-left">{{L.pleaseSelectDelivery}}:</span>
          <div class="select" @mouseleave="mouseleave(2)">
            <div class="choose" @click="show.showChooseDelivery=!show.showChooseDelivery">
              <div class="text">
              <div class="text1">
                {{chooseDelivery.addressTitle}} {{chooseDelivery.siteUseLocation}}
              </div>
                <div class="text2">{{chooseDelivery.addressInfo}}</div>
              </div>
              <span class="iconfont arrow" :class="{'active-arrow':show.showChooseDelivery}">&#xe627;</span>
            </div>
            <!-- 弹框 -->
            <div class="choose-wrap" v-show="show.showChooseDelivery">
              <div class="choose" @click="chooseDeliverySelect(item)" v-for="item in deliveryList" :key="item.addressId">
                <div class="line1">{{item.addressTitle}} {{item.siteUseLocation}}</div>
                <div class="line2">{{item.addressInfo}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="please">
          <!-- 请选择交货日期 -->
          <span class="please-left">{{L.PleaseChooseDate}}:</span>
          <el-date-picker
            style="width:200px;"
            v-model="allDate"
            type="date"
            value-format="yyyy-MM-dd"
            format="dd-MM-yyyy"
            :picker-options="pickerOptions"
            :placeholder="L.pleaseChoose">
          </el-date-picker>
          <!-- 应用于所有行 -->
          <div class="apply-btn" @click="changeAllDate">{{L.ApplyAllLines}}</div>
        </div>
      </div>
    </div>
    <!-- 产品概述 -->
    <div class="product-overview">
      <!-- 大标题 -->
      <div class="title">
        <!-- 订单详情 -->
        <span class="text">{{L.productOverview}}</span>
        <!-- 个产品项 -->
        <span class="quantity">{{$store.state.cartCount}} {{L.orderltems}}</span>
      </div>

      <!-- 标准产品 -->
      <div class="product-square" v-show="productList.length>0">
        <div class="quote-title">
          <!-- <span class="title-text">Standard Product</span> -->
        </div>
        <!-- 一大行 -->
        <div class="line" v-for="item in productList" :key="item.cartHead && item.cartHead.skuId">
          <!-- 内容标题 -->
          <div class="line-title">
            <div class="info"></div>
            <!-- 需求的交货日期 -->
            <div class="date">{{L.requestedDeliveryDate}}:</div>
            <!-- 数量 -->
            <div class="quantity">{{L.quantity}}:</div>
            <div class="price"></div>
          </div>
          <!-- 主内容 -->
          <div class="main-content">
            <!-- 左边的 -->
            <div class="left">
              <div class="info">
                <div class="image" :class="{'no-image':item.ifVisible!=1}">
                  <img v-show="item.ifVisible==1" :src="item.cartHead.loopImageList && item.cartHead.loopImageList[0]">
                </div>
                <div class="text">
                  <div class="text1">
                    <span v-if="item.canJump!=1" class="span1" href="javascript:;" @click="handleSkuName">{{item.cartHead && item.cartHead.skuName}}</span>
                    <a v-if="item.canJump==1" class="span1" :href="detailUrl(1,item.eanCode)">
                      <span v-html="item.cartHead && item.cartHead.skuName"></span>
                    </a>
                  </div>
                  <div class="text2">{{item.cartHead && item.cartHead.eanOrIc}} {{item.cartHead && item.cartHead.skuId}}</div>
                  <!-- <div class="text2">Successor Item(s):接口没数据</div> -->
                  <!-- 小图标 -->
                  <div class="small-pic" v-if="item.ifVisible==1">
                    <img class="img img1" :src="item.cartHead && item.cartHead.energyLogoImage" v-show="item.cartHead && item.cartHead.energyLogoImage"/>
                    <img class="big-img" :src="item.cartHead && item.cartHead.energyBigImage"/>
                    <a class="icon-eprel-wrap" v-if="item.cartHead && item.cartHead.eprelLink" :href="item.cartHead.eprelLink" target="_blank">
                      <span class="iconfont icon-eprel">&#xe621;</span>
                    </a>
                    <!-- <img class="img" v-for="item2 in item.cartHead.logoImageList" :key="item2" :src="item2"/> -->
                    <!-- <div class="icons" v-show="item.cartHead.dataSheetLink">
                      <span class="iconfont icon-file" @click="downloadProduct(item.cartHead.dataSheetLink,1)">&#xe60a;</span>
                      <span class="iconfont icon-download" @click="downloadProduct(item.cartHead.dataSheetLink,0)">&#xe61c;</span>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- 右边的一行 -->
            <div class="right">
              <div class="right-line" v-for="(item2,i2) in item.cartLineList" :key="item2.cartId">
                <!-- 基本内容 -->
                <div class="basic-info">
                  <div class="date">
                    <el-date-picker
                      :clearable="false"
                      value-format="yyyy-MM-dd"
                      format="dd-MM-yyyy"
                      @focus="focusDate(item2)"
                      @change="e=>handleDateChange(e,item2,item)"
                      style="width:100%"
                      v-model="item2.deliveryDate"
                      type="date"
                      :picker-options="pickerOptions"
                      :placeholder="L.pleaseChoose">
                    </el-date-picker>
                  </div>
                  <div class="quantity">
                    <AddNumber 
                      paddingTopBottom="9px" 
                      v-model="item2.skuCount" 
                      :step="item2.countAscii" 
                      @blur="e=>blurCount(e,item2,item,1)" 
                      @click="e=>blurCount(e,item2,item,1)"
                      @focus="e=>focusCount(e,item2)"
                    />
                  </div>
                  <!-- 价格 -->
                  <div class="price">
                    <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP')">
                      <div class="left-price" v-show="item2.skuAmount">
                        <div class="price-num">{{formatMoney(item2.skuAmount,item2.customerCurrency)}}</div>
                        <div class="carton-box">({{formatMoney(item2.skuPrice,item2.currency)}} per {{item2.assemblyType}})</div>
                      </div>
                      <!-- 无论是null 还是'' 还是0 布尔值都是false，只要是false，就要展示Price on request -->
                      <div class="left-price" v-show="!item2.skuAmount">
                        <!-- 价格需要申请 -->
                        <div class="carton-box">{{L.priceRequest}}</div>
                      </div>
                    </template>
                    <!-- 添加收藏夹 -->
                    <div class="right-wishlist-icon">
                      <template v-if="i2==0">
                        <el-tooltip 
                          class="item" 
                          effect="dark"
                          placement="top"
                        >
                        <div slot="content">{{L.addToWishlist}}</div>
                          <span @click="handleShowWishlist(2,item)" class="iconfont icon-wishlist">&#xe60e;</span>
                        </el-tooltip>
                        <div class="wishlist-wrap" v-show="item.showBodyWishlist">
                          <WishlistSelect ref="BodyWishlistSelect" @closeWishlistDialogue="closeWishlistDialogue(2,item)"/>
                        </div>
                      </template>
                    </div>
                    <div class="delete">
                      <span class="iconfont icon-delete" @click="deleteGoods(item2)">&#xec7b;</span>
                    </div>
                  </div>
                </div>
                <!-- 装箱内容 -->
                <div class="carton-box-remark" v-show="item2.qtyMsgList.length>=1 && item.ifVisible==1">
                  <div class="line-title">
                    <div class="date"></div>
                    <div class="quantity">
                      <div class="line-one">
                        <span 
                          class="iconfont icon-arrow"
                          :class="{'icon-active':item2.showCarton}"
                          @click="clickCartonFold(item,item2,1)"
                        >&#xe66c;</span>
                        <span class="text">{{item2.qtyMsgList[0]}}</span>
                      </div>
                      <transition
                        @enter="enter"
                        @afterEnter="afterEnter"
                        @leave="leave"
                        @afterLeave="afterLeave"
                      >
                      <div v-show="item2.showCarton" class="line-more-wrap">
                        <div class="line-more" v-for="item3,i in item2.qtyMsgList" :key="i">{{i>=1?item3:null}}</div>
                      </div>
                      </transition>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 按钮-拆箱 -->
          <div class="split-btn-wrap">
            <!-- <div class="wishlist">Add to wishlist</div> -->
            <div class="wishlist"></div>
            <div class="split">
              <!-- 添加交货日期/拆分数量 -->
              <span @click="addDelivery(item)">{{L.addDeliverySplitQuantity}}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- quote产品 -->
      <div class="product-square" v-show="quoteList.length>0" v-for="obj in quoteList" :key="obj.quoteHeadId">
        <div class="quote-title">
          <a class="title-text" :href="quoteDetailUrl(obj.quoteNumber)">Quote {{obj.quoteNumber}}</a>
          <span class="iconfont icon-delete" @click="deleteQuote(obj)">&#xec7b;</span>
        </div>
        <!-- 一大行 -->
        <div class="line" v-for="item in obj.lineListVos" :key="item.cartHead && item.cartHead.skuId">
          <!-- 内容标题 -->
          <div class="line-title">
            <div class="info"></div>
            <!-- 需求的交货日期 -->
            <div class="date">{{L.requestedDeliveryDate}}:</div>
            <!-- 数量 -->
            <div class="quantity">
              <span>{{L.quantity}}: </span>
              <span class="min">min. <b>{{item.minQty}}</b> max. <b>{{item.maxQty}}</b></span>
              <!-- 数量错误报错 -->
              <div class="wrong-num" v-show="item.qtyIsErr==1">
                <span class="iconfont icon-warning">&#xe6aa;</span>
                <span class="text">Warning: The quantity is out of range, please adjust the quantity.</span>
              </div>
            </div>
            <div class="price"></div>
          </div>
          <!-- 主内容 -->
          <div class="main-content">
            <!-- 左边的 -->
            <div class="left">
              <div class="info">
                <div class="image" :class="{'no-image':item.ifVisible!=1}">
                  <img v-show="item.ifVisible==1" :src="item.cartHead.loopImageList && item.cartHead.loopImageList[0]">
                </div>
                <div class="text">
                  <div class="text1">
                    <span v-if="item.canJump!=1" class="span1" href="javascript:;" @click="handleSkuName">{{item.cartHead && item.cartHead.skuName}}</span>
                    <a v-if="item.canJump==1" class="span1" :href="detailUrl(1,item.eanCode)">
                      <span v-html="item.cartHead && item.cartHead.skuName"></span>
                    </a>
                  </div>
                  <div class="text2">{{item.cartHead && item.cartHead.eanOrIc}} {{item.cartHead && item.cartHead.skuId}}</div>
                  <!-- <div class="text2">Successor Item(s):接口没数据</div> -->
                  <!-- 小图标 -->
                  <div class="small-pic" v-if="item.ifVisible==1">
                    <img class="img img1" :src="item.cartHead && item.cartHead.energyLogoImage" v-show="item.cartHead && item.cartHead.energyLogoImage"/>
                    <img class="big-img" :src="item.cartHead && item.cartHead.energyBigImage"/>
                    <a class="icon-eprel-wrap" v-if="item.cartHead && item.cartHead.eprelLink" :href="item.cartHead.eprelLink" target="_blank">
                      <span class="iconfont icon-eprel">&#xe621;</span>
                    </a>
                    <!-- <img class="img" v-for="item2 in item.cartHead.logoImageList" :key="item2" :src="item2"/> -->
                    <!-- <div class="icons" v-show="item.cartHead.dataSheetLink">
                      <span class="iconfont icon-file" @click="downloadProduct(item.cartHead.dataSheetLink,1)">&#xe60a;</span>
                      <span class="iconfont icon-download" @click="downloadProduct(item.cartHead.dataSheetLink,0)">&#xe61c;</span>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- 右边的一行 -->
            <div class="right">
              <div class="right-line" v-for="item2 in item.cartLines" :key="item2.cartId">
                <!-- 基本内容 -->
                <div class="basic-info">
                  <div class="date">
                    <el-date-picker
                      :clearable="false"
                      value-format="yyyy-MM-dd"
                      format="dd-MM-yyyy"
                      @focus="focusDate(item2)"
                      @change="e=>handleDateChange(e,item2,item)"
                      style="width:100%"
                      v-model="item2.deliveryDate"
                      type="date"
                      :picker-options="pickerOptions"
                      :placeholder="L.pleaseChoose">
                    </el-date-picker>
                  </div>
                  <div class="quantity">
                    <AddNumber
                      :disabled="!item2.canModifyQty"
                      paddingTopBottom="9px" 
                      v-model="item2.skuCount" 
                      :step="item2.countAscii" 
                      @blur="e=>blurCount(e,item2,item,2,obj)" 
                      @click="e=>blurCount(e,item2,item,2,obj)"
                      @focus="e=>focusCount(e,item2)"
                    />
                  </div>
                  <!-- 价格 -->
                  <div class="price">
                    <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP')">
                      <div class="left-price" v-show="item2.skuAmount">
                        <div class="price-num">{{formatMoney(item2.skuAmount,item2.customerCurrency)}}</div>
                        <div class="carton-box">({{formatMoney(item2.skuPrice,item2.currency)}} per {{item2.assemblyType}})</div>
                      </div>
                      <!-- 无论是null 还是'' 还是0 布尔值都是false，只要是false，就要展示Price on request -->
                      <div class="left-price" v-show="!item2.skuAmount">
                        <!-- 价格需要申请 -->
                        <div class="carton-box">{{L.priceRequest}}</div>
                      </div>
                    </template>
                    <!-- 添加收藏夹 -->
                    <!-- <div class="right-wishlist-icon" v-if="i2==0">
                      <el-tooltip 
                        class="item" 
                        effect="dark"
                        placement="top"
                      >
                      <div slot="content">{{L.addToWishlist}}</div>
                        <span @click="handleShowWishlist(2,item)" class="iconfont icon-wishlist">&#xe60e;</span>
                      </el-tooltip>
                      <div class="wishlist-wrap" v-show="item.showBodyWishlist">
                        <WishlistSelect ref="BodyWishlistSelect" @closeWishlistDialogue="closeWishlistDialogue(2,item)"/>
                      </div>
                    </div> -->
                    <div class="delete">
                      <span v-if="item2.canDelete" class="iconfont icon-delete" @click="deleteGoods(item2)">&#xec7b;</span>
                    </div>
                  </div>
                </div>
                <!-- 装箱内容 -->
                <div class="carton-box-remark" v-show="item2.qtyMsgList.length>=1 && item.ifVisible==1">
                  <div class="line-title">
                    <div class="date"></div>
                    <div class="quantity">
                      <div class="line-one">
                        <span 
                          class="iconfont icon-arrow"
                          :class="{'icon-active':item2.showCarton}"
                          @click="clickCartonFold(item,item2,2,obj)"
                        >&#xe66c;</span>
                        <span class="text">{{item2.qtyMsgList[0]}}</span>
                      </div>
                      <transition
                        @enter="enter"
                        @afterEnter="afterEnter"
                        @leave="leave"
                        @afterLeave="afterLeave"
                      >
                      <div v-show="item2.showCarton" class="line-more-wrap">
                        <div class="line-more" v-for="item3,i in item2.qtyMsgList" :key="i">{{i>=1?item3:null}}</div>
                      </div>
                      </transition>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 按钮-拆箱 -->
          <div class="split-btn-wrap" v-if="item.canSplit">
            <!-- <div class="wishlist">Add to wishlist</div> -->
            <div class="wishlist"></div>
            <div class="split">
              <!-- 添加交货日期/拆分数量 -->
              <span @click="addDelivery(item)">{{L.addDeliverySplitQuantity}}</span>
            </div>
          </div>
        </div>
      </div>
      
      <!-- 下面-清除购物车 -->
      <div class="clear-cart-wrap">
        <div class="clear-cart" @click="clearCart">
          <span class="text">{{L.clearCart}}</span>
          <span class="iconfont icon-clear">&#xec7b;</span>
        </div>
      </div>
    </div>

    <!-- quick order对话框 -->
    <QuickOrder ref="QuickOrder" @addRequest="addRequest"/>
    <AddCartErr ref="AddCartErr" />
    <AddCartResult ref="AddCartResult" />
    <RequestQuotation ref="RequestQuotation" @requestQuatation="requestQuatation" />
    <QuoteSuccess ref="QuoteSuccess" />
  </div>
</template>

<script>
import {
  api_cart_list,
  api_cart_split,
  api_cart_delete,
  api_cart_update_date,
  api_cart_update_count,
  api_cart_update_all_date,
  api_cart_clear_all,
  api_cart_address,
  api_download_cart,
  api_cart_check_price,
  api_quick_order,
  api_wishlist_list,
  api_quotation_cart,
  api_cart_delete_quote,
  api_before_quote_judge
} from '@/api/api' 
import {downFilePost,postAction,downFileGet,getAction} from '@/api/tool'
import AddNumber from '@/components/AddNumber'
import QuickOrder from '@/components/QuickOrder'
import AddCartErr from '@/components/AddCartErr'
import AddCartResult from '@/components/AddCartResult'
import RequestQuotation from '@/components/Quote/RequestQuotation'
import QuoteSuccess from '@/components/Quote/QuoteSuccess'
import Vue from 'vue'
import moment from 'moment'
import WishlistSelect from '@/components/WishlistSelect'

export default {
  name:'Overview',
  components:{
    AddNumber,
    QuickOrder,
    AddCartErr,
    AddCartResult,
    WishlistSelect,
    RequestQuotation,
    QuoteSuccess
  },
  data(){
    return {
      show:{
        showChooseAddress:false, //是否展示地址选择框
        showChooseDelivery:false, //是否展示交货方的地址选择
        showHeadWishlist:false //单头收藏夹
      },
      wishlistList:[],//收藏夹列表
      totalPrice:0, //总价
      productList:[],//购物车产品列表
      quoteList:[], //quote列表
      allDate:'', //所有的交期
      addressList:[],//地址列表
      deliveryList:[], //收货方的地址列表
      chooseAddress:{},//选择的地址
      chooseDelivery:{}, //选择的收货方的地址
      warningMessage:'', //警告信息
      canChageShipTo:0, //能不能修改ship to，0不能修改，1可以修改
      pickerOptions:{
        disabledDate: e => {
          let chooseDate = moment(new Date(e)).format('YYYY-MM-DD') //当选择的日期
          let chooseTime = new Date(chooseDate).valueOf() //选择的时间戳
          let date = moment(new Date()).format('YYYY-MM-DD') //当前日期
          let dateTime = new Date(date).valueOf() //当前日期时间戳
          //大于等于今天的时间，可以选择。反之不能选择
          if(chooseTime>=dateTime){
            return false
          }else{
            return true
          }
        }
      }
    }
  },
  computed:{
    allQtyIsErrFlag(){
      let allQtyIsErr = false
      //如果quote list里的数据的qtyIsErr都是0，才可以往下走
      this.quoteList.forEach(val=>{
        val.lineListVos.forEach(val2=>{
          if(val2.qtyIsErr==1){
            allQtyIsErr=true
          }
        })
      })
      return allQtyIsErr
    }
  },
  methods:{
    /* 切换ship to下拉 */
    triggerShowShipTo(){
      if(this.canChageShipTo){
        this.show.showChooseAddress=!this.show.showChooseAddress
      }
    },
    /* 打开快速加入购物车 */
    showQuickOrder(){
      this.$refs.QuickOrder.showQuickOrder=true
    },
    /* 快速加入购物车,子组件触发本组件的这个方法 */
    addRequest(){
      let params={
        quickContent:this.$refs.QuickOrder.quickOrder
      }
      this.$refs.QuickOrder.loadingQuick=true
      postAction(api_quick_order,params).then(res=>{
        this.$refs.QuickOrder.loadingQuick=false
        this.getCartList(1) //这个方法包含了获取购物车总数
        //0说明全部成功
        if(res.errFlag==0){
          this.$refs.AddCartResult.open(res.errFlag)
          this.$refs.QuickOrder.closeQuickOrder()
        //1说明部分成功,2说明失败
        }else{
          this.$refs.AddCartResult.open(res.errFlag,res.errList)
        }
      }).catch(res=>{
        this.$refs.QuickOrder.loadingQuick=false
        this.$message.error(res.msg)
      })
    },
    /* 获取购物车列表 */
    getCartList(flag=0){
      //如果传入了参数1，那就不需要loading
      if(flag!=1){
        this.$emit('openLoading')
      }
      postAction(api_cart_list).then(res=>{
        this.$emit('closeLoading')
        this.$emit('getCartList',res)
        this.$store.dispatch('setCartCount',res.itemCount)
        this.productList=res.cartList || []
        this.canChageShipTo=res.canChageShipTo
        this.quoteList=res.quoteList || []
        this.totalPrice=res.totalPrice
        this.warningMessage=res.cartRemoveMsg
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    },
    /* 裂开一条 */
    addDelivery(item){
      this.$emit('openLoading')
      let params = {
        skuId:item.cartHead.skuId,
        quoteLineId:item.quoteLineId
      }
      postAction(api_cart_split,params).then(()=>{
        this.$emit('closeLoading')
        this.getCartList()
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    },
    /* 删除一条购物车 */
    deleteGoods(item2){
      this.$emit('openLoading')
      let params={
        cartId:item2.cartId
      }
      postAction(api_cart_delete,params).then(()=>{
        this.$emit('closeLoading')
        this.getCartList()
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    },
    /* 聚焦的时候先把原来的日期数据先记录下来 */
    focusDate(item2){
      item2.oldDate=item2.deliveryDate
    },
    /* 修改交期-单个 */
    handleDateChange(e,item2,item){
      //如果选了日期,走这段代码。没选日期可以直接走下面的
      if(e){
        let chooseTime = new Date(e).valueOf() //选择的时间戳
        let date = moment(new Date()).format('YYYY-MM-DD') //当前日期
        let dateTime = new Date(date).valueOf() //当前日期时间戳
        //如果选择的日期小于当前日期，那就不要执行下面的请求了，并且提示错误
        if(chooseTime<dateTime){
          // 需求的交货日期不能小于今天
          this.$message.warning('Request Delivery Date Can not be less than today')
          this.productList.forEach((val,i)=>{
            if(item.cartHead.skuId==val.cartHead.skuId){
              val.cartLineList.forEach((val2,i2)=>{
                if(val2.cartId==item2.cartId){
                  val.cartLineList[i2].deliveryDate=val2.oldDate
                  this.$set(this.productList,i,val) //触发渲染
                }
              })
            }
          })
          return
        }
      }
      
      let params = {
        cartId:item2.cartId,
        deliveryDate:e
      }
      this.$emit('openLoading')
      postAction(api_cart_update_date,params).then(()=>{
        this.$emit('closeLoading')
        this.getCartList()
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    },
    /* 聚焦的时候先把原来的数据先记录下来，这样如果后面失焦的时候就可以控制如果数据是一样的就不用修改数量了 */
    focusCount(e,item2){
      item2.oldVal=e
    },
    /* 修改数量 */
    blurCount(e,item2,item,flag,quoteObj){
      let num = e
      let countAscii = item2.countAscii //进制
      let moq = item2.moq //最小值

      num = num%countAscii==0?num:(Math.floor(num/countAscii+1))*countAscii
      num = num>=moq?num:moq

      //1.新的数据和老的数据一样的话，就不要请求了。并且这里还要注意把div的数据放回去，否则会出错。
      //2.举例，进制是2，从4改成3，oldVal是4，num也被进制弄成了4，但是input框的数据还是3
      //3.要解决第二步，就要重新给skuCount赋个值，相当于skuCount已经被改掉了，但是这边如果相等又被return掉了，所以要重新赋个值即可
      //flag为1是正常产品，flag为2是quote的产品(quote的数据，数据结构多了一层)
      if(flag==1){
        this.productList.forEach((val,i)=>{
          if(item.cartHead.skuId==val.cartHead.skuId){
            val.cartLineList.forEach((val2,i2)=>{
              if(val2.cartId==item2.cartId){
                val.cartLineList[i2].skuCount=num
                this.$set(this.productList,i,val) //触发渲染
              }
            })
          }
        })
      }
      if(flag==2){
        this.quoteList.forEach((val,i)=>{
          //找到第一层
          if(quoteObj.quoteHeadId===val.quoteHeadId){
            //找到第二层
            val.lineListVos.forEach((val2,i2)=>{
              if(val2.quoteLineId===item.quoteLineId){
                //找到第三层
                val2.cartLines.forEach((val3,i3)=>{
                  if(val3.cartId===item2.cartId){
                    val.lineListVos[i2].cartLines[i3].skuCount=num
                    this.$set(this.quoteList,i,val)
                  }
                })
              }
            })
          }
        })
      }
      
      //如果值是一样的，就不用请求了。要注意，先赋值，再return
      if(item2.oldVal==num){
        return
      }

      let params = {
        cartId:item2.cartId,
        skuCount:num
      }
      this.$emit('openLoading')
      postAction(api_cart_update_count,params).then(res=>{
        let resultNum = res.skuCount
        this.totalPrice=res.totalPrice
        //把后端的值赋进去
        if(flag==1){
          this.productList.forEach((val,i)=>{
            if(item.cartHead.skuId==val.cartHead.skuId){
              val.cartLineList.forEach((val2,i2)=>{
                if(val2.cartId==item2.cartId){
                  val.cartLineList[i2].skuCount=resultNum
                  val.cartLineList[i2].skuAmount=res.skuAmount
                  val.cartLineList[i2].skuPrice=res.skuPrice
                  this.$set(this.productList,i,val) //触发渲染
                }
              })
            }
          })
        }
        if(flag==2){
          this.quoteList.forEach((val,i)=>{
            //找到第一层
            if(quoteObj.quoteHeadId===val.quoteHeadId){
              //找到第二层
              val.lineListVos.forEach((val2,i2)=>{
                if(val2.quoteLineId===item.quoteLineId){
                  //找到第三层
                  val2.cartLines.forEach((val3,i3)=>{
                    if(val3.cartId===item2.cartId){
                      val.lineListVos[i2].cartLines[i3].skuCount=resultNum
                      val.lineListVos[i2].cartLines[i3].skuAmount=res.skuAmount
                      val.lineListVos[i2].cartLines[i3].skuPrice=res.skuPrice
                      val.lineListVos[i2].qtyIsErr=res.qtyIsErr
                      this.$set(this.quoteList,i,val)
                    }
                  })
                }
              })
            }
          })
        }
        this.$emit('closeLoading')
        // this.getCartList()
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    },
    /* 修改所有的交期 */
    changeAllDate(){
      if(!this.allDate){
        //请选择日期
        this.$message.warning(this.L.pleaseSelectDate)
        return
      }
      let params={
        deliveryDate:this.allDate
      }
      this.$emit('openLoading')
      postAction(api_cart_update_all_date,params).then(()=>{
        this.$emit('closeLoading')
        this.getCartList()
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    },
    /* 获取下拉框地址 */
    getAdress(){
      postAction(api_cart_address).then(res=>{
        this.addressList=res.addressVo.shipTo
        this.chooseAddress=res.defaultShipTo
        this.deliveryList=res.addressVo.deliveryTo
        //这个送货方不一定有，所以如果有的话就默认取第一个，没有就不用取了
        if(this.deliveryList.length>0){
          this.chooseDelivery=res.addressVo.deliveryTo[0]
        }
      })
    },
    //地址下拉框的鼠标移出
    mouseleave(flag){
      if(flag==1){
        this.show.showChooseAddress=false
      }
      if(flag==2){
        this.show.showChooseDelivery=false
      }
    },
    /* 选中地址下拉框中的其中一个 */
    chooseAddressSelect(item){
      this.show.showChooseAddress=false
      this.chooseAddress=item
    },
    chooseDeliverySelect(item){
      this.show.showChooseDelivery=false
      this.chooseDelivery=item
    },
    /* 清除购物车 */
    clearCart(){
      // 确认清除所有吗？
      this.$confirm(this.L.sureClearAll, {
        confirmButtonText: this.promptConfirmBtn,
        cancelButtonText: this.propmtCancelBtn,
        closeOnClickModal:this.promptCloseFlag,
        type: 'warning',
        lockScroll:false,
        customClass:'message-box',
      }).then(() => {
        this.$emit('openLoading')
        postAction(api_cart_clear_all).then(()=>{
          this.$emit('closeLoading')
          this.getCartList()
        })
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    },
    /* 导出购物车 */
    downloadCart(){
      this.$emit('openLoading')
      downFilePost(api_download_cart).then(res=>{
        this.$emit('closeLoading')
        this.downloadFile(res)
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    },
    /* 价格校验 */
    priceCheck(){
      if(this.$store.state.userInfo.accountRules && this.$store.state.userInfo.accountRules.includes('NOC')){
        return
      }
      let allQtyIsErr = false
      //如果quote list里的数据的qtyIsErr都是0，才可以往下走
      this.quoteList.forEach(val=>{
        val.lineListVos.forEach(val2=>{
          if(val2.qtyIsErr==1){
            allQtyIsErr=true
          }
        })
      })
      if(allQtyIsErr){
        this.$message.warning('The quantity is out of range, please adjust the quantity.')
        return
      }
      let params={
        shipToId:this.chooseAddress.addressId,
        deliveryToId:this.chooseDelivery.addressId
      }
      this.$emit('openLoading')
      postAction(api_cart_check_price,params).then(res=>{
        this.$emit('closeLoading')
        this.$emit('changeFocusFlag',2)
        this.$emit('setDeliveryData',res)
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    },
    /* 点击展开与折叠装箱信息,1是标准产品,2是quote产品 */
    clickCartonFold(item,item2,flag,quoteObj){
      if(flag==1){
        this.productList.forEach((val,i)=>{
          if(item.cartHead.skuId===val.cartHead.skuId){
            val.cartLineList.forEach((val2,i2)=>{
              if(val2.cartId===item2.cartId){
                val.cartLineList[i2].showCarton=!val.cartLineList[i2].showCarton
                this.$set(this.productList,i,val)
              }
            })
          }
        })
      }
      if(flag==2){
        this.quoteList.forEach((val,i)=>{
          //找到第一层
          if(quoteObj.quoteHeadId===val.quoteHeadId){
            //找到第二层
            val.lineListVos.forEach((val2,i2)=>{
              if(val2.quoteLineId===item.quoteLineId){
                val2.cartLines.forEach((val3,i3)=>{
                  if(val3.cartId===item2.cartId){
                    val.lineListVos[i2].cartLines[i3].showCarton=!val.lineListVos[i2].cartLines[i3].showCarton
                    this.$set(this.quoteList,i,val)
                  }
                })
              }
            })
          }
        })
      }
    },
    /* 下载产品文件 */
    downloadProduct(src,flag){
      let url=src.includes('inventronicsglobal')?src.split(Vue.config.DAM_URL)[1]:src.split(Vue.config.OSR_URL)[1]
      url=this.decodeHtml('/'+url)
      this.$emit('openLoading')
      downFileGet(url).then(res=>{
        this.$emit('closeLoading')
        this.downloadFile(res,flag)
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    },
    /* 如果ifVisible标记为0，点击skuName，报错提示 */
    handleSkuName(){
      this.$message.warning(this.L.sorryResult)
    },
    /* 获取wishlist列表的数据 */
    getWishlistList(){
      let params = {
        isShare:1
      }
      getAction(api_wishlist_list,params).then(res=>{
        this.wishlistList=res
      })
    },
    /* 展示单头的wishlist */
    handleShowWishlist(flag,item){
      if(flag==1){
        this.productList.forEach((val)=>{
          val.showBodyWishlist=false
        })
        this.show.showHeadWishlist=true
        this.productList.forEach((val)=>{
          val.eanPimid=val.cartHead.skuId
        })
        this.$refs.HeadWishlistSelect.setNowProductItem(this.productList,this.wishlistList,true) //打开whishlist对话框
      }
      if(flag==2){
        this.show.showHeadWishlist=false
        item.eanPimid=item.cartHead.skuId
        let arr = [item]
        this.productList.forEach((val,i)=>{
          val.showBodyWishlist=false
          if(val.cartHead.skuId==item.cartHead.skuId){
            val.showBodyWishlist=true
            this.$set(this.productList,i,val) //触发渲染
            this.$refs.BodyWishlistSelect[i].setNowProductItem(arr,this.wishlistList,true) //打开whishlist对话框
          }
        })
      }
    },
    /* 关闭单头的wishlist对话框 */
    closeWishlistDialogue(flag,item){
      if(flag==1){
        this.show.showHeadWishlist=false
      }
      if(flag==2){
        this.productList.forEach((val,i)=>{
          if(val.cartHead.skuId==item.cartHead.skuId){
            val.showBodyWishlist=false
            this.$set(this.productList,i,val) //触发渲染
          }
        })
      }
    },
    /* 展示quotation弹窗 */
    handleRequestQuo(){
      this.$emit('openLoading')
      postAction(api_before_quote_judge).then(res=>{
        this.$emit('closeLoading')
        if(res.code==200){
          this.$refs.RequestQuotation.open(1)
        }else{
          this.$refs.AddCartErr.open(res.message)
        }
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    },
    /* quotation弹窗提交按钮触发父组件方法 */
    requestQuatation(params){
      this.$refs.RequestQuotation.loading=true
      postAction(api_quotation_cart,params).then(res=>{
        this.$refs.RequestQuotation.loading=false
        //大于0说明是失败的，等于0说明是成功的
        if(res.length>0){
          //失败
          this.$refs.AddCartErr.open(res)
        }else{
          //成功
          this.$refs.QuoteSuccess.open()
          this.$refs.RequestQuotation.close()
        }
      }).catch(res=>{
        this.$refs.RequestQuotation.loading=false
        this.$message.error(res.msg)
      })
    },
    /* 删除Quote */
    deleteQuote(obj){
      this.$emit('openLoading')
      let params={
        quoteHeadId:obj.quoteHeadId
      }
      postAction(api_cart_delete_quote,params).then(()=>{
        this.$emit('closeLoading')
        this.getCartList()
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    }
  },
  mounted(){
    this.getCartList()
    this.getAdress()
    this.getWishlistList()
  }
}
</script>

<style lang="less" scoped>
@import url('../../../../public/css/public.less');
@import url('./index.less');
</style>